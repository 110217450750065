import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';

import Field, { hasDataInFields } from '../../Field';
import { ExternalLink } from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionCustomPopularLocation.module.css';

import salida from './media/salida.jpg';
import jefferson from './media/jefferson.jpg';
import carbondale from './media/carbondale.jpg';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionCustomPopularLocation = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  return (
    <SectionContainer
      id={sectionId}
      className={css.sectionContainer}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={css.sectionDetails}>
          <Field data={title} className={css.title} options={fieldOptions} />
          <Field data={description} className={css.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}

      <div className={css.locationsSectionContainer}>
        <ExternalLink
          className={css.locationCard}
          href="s?address=Salida%2C%20Colorado%2C%20United%20States&bounds=38.750848%2C-105.852653%2C38.409394%2C-106.358188"
          targetSelf
        >
          <div className={css.imgContainer}>
            <img className={css.locationImg} src={salida}></img>
          </div>
          <p className={css.locationsTitle}>Salida, Colorado</p>
        </ExternalLink>

        <ExternalLink
          className={css.locationCard}
          href="/s?address=Carbondale%2C%20Colorado%2C%20United%20States&bounds=39.592269%2C-106.921544%2C39.013675%2C-107.446388"
          targetSelf
        >
          <div className={css.imgContainer}>
            <img className={css.locationImg} src={carbondale}></img>
          </div>
          <p className={css.locationsTitle}>Carbondale, Colorado</p>
        </ExternalLink>
        <ExternalLink
          className={css.locationCard}
          href="/s?address=McCoy%2C%20Colorado%2C%20United%20States&bounds=40.25352631%2C-106.38235249%2C39.49565141%2C-107.26125205"
          targetSelf
        >
          <div className={css.imgContainer}>
            <img className={css.locationImg} src={jefferson}></img>
          </div>
          <p className={css.locationsTitle}>McCoy, Colorado</p>
        </ExternalLink>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCustomPopularLocation.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionCustomPopularLocation.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionCustomPopularLocation;
