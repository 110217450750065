import React from 'react';
import { array, arrayOf, bool, func, node, object, shape, string } from 'prop-types';

import Field, { hasDataInFields } from '../../Field';
import { ExternalLink, NamedLink, PrimaryButton, ReviewRating } from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionCustomArticle.module.css';

import { createSlug } from '../../../../util/urlHelpers';
import { intlShape, injectIntl } from '../../../../util/reactIntl';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionCustomArticle = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    cardData,
    intl,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const availabilityBadgeText = intl.formatMessage({
    id: 'ReviewsGridComponent.campingAvailability',
  });
  const viewLocationButtonText = intl.formatMessage({ id: 'LandingPage.viewLocation' });

  const gridContainerStyle = {
    backgroundImage: cardData.backgroundImage ? `url(${backgroundImage})` : 'none',
  };

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}

      <div className={css.sectionContainer}>
        <div className={css.overnightCampingInfo}>
          <h3>Make it an overnight, explore exclusive private camping</h3>
          <p>
            Escape the crowds, wake up to solitude and serenity with direct access to pristine
            angling. RareWaters makes extended fishing trips possible with direct access to
            properties with camping and even RV spots available!
          </p>

          {isMobile ? (
            <div className={css.cardDataContainer}>
              {cardData.map(item => (
                <NamedLink
                  className={css.listingNamedLink}
                  key={item.listingId}
                  name="ListingPage"
                  params={{ id: item.listingId, slug: createSlug(item.contentTitle) }}
                >
                  <div className={css.sectionItem}>
                    <div className={css.itemContainer}>
                      {item.backgroundImage && (
                        <div className={css.imgContainer}>
                          <div
                            className={css.image}
                            style={{
                              ...gridContainerStyle,
                              backgroundImage: `url(${item.backgroundImage})`,
                            }}
                          ></div>
                          {item.campingAvailable && (
                            <div className={css.availabilityBadge}> {availabilityBadgeText} </div>
                          )}
                          <div className={css.contentContainer}>
                            <div className={css.titleContainer}>
                              <h2 className={css.contentTitle}>{item.contentTitle}</h2>
                              <h6 className={css.location}>{item.location}</h6>
                            </div>

                            {typeof item.content !== 'undefined' ? (
                              <>
                                <p className={css.content}>{item.content}</p>
                                <ReviewRating
                                  reviewStarClassName={css.reviewStar}
                                  className={css.reviewStars}
                                  rating={item.rating}
                                />
                              </>
                            ) : (
                              <>
                                <p className={css.content}>
                                  <FormattedMessage id="ReviewGrid.noReview" />
                                </p>
                                <ReviewRating
                                  reviewStarClassName={css.reviewStar}
                                  className={css.reviewStars}
                                  rating={0}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </NamedLink>
              ))}
            </div>
          ) : null}
          <ExternalLink
            className={css.routerLink}
            href="/s?bounds=50.62469868%2C-105.45479685%2C34.08157998%2C-124.48328838&mapSearch=true&pub_features_and_amenities=has_all%3Acamping%2Ctent-camping"
            targetSelf
          >
            <PrimaryButton className={css.button}>{viewLocationButtonText}</PrimaryButton>
          </ExternalLink>
        </div>

        {isMobile === false ? (
          <div className={css.cardDataContainer}>
            {cardData.map((item, index) => (
              <NamedLink
                className={css.listingNamedLink}
                key={item.listingId}
                name="ListingPage"
                params={{ id: item.listingId, slug: createSlug(item.contentTitle) }}
              >
                <div className={css.sectionItem}>
                  <div className={css.itemContainer}>
                    {item.backgroundImage && (
                      <div className={css.imgContainer}>
                        <div
                          className={css.image}
                          style={{
                            ...gridContainerStyle,
                            backgroundImage: `url(${item.backgroundImage})`,
                          }}
                        ></div>
                        {item.campingAvailable && (
                          <div className={css.availabilityBadge}> {availabilityBadgeText} </div>
                        )}
                        <div className={css.contentContainer}>
                          <div className={css.titleContainer}>
                            <h2 className={css.contentTitle}>{item.contentTitle}</h2>
                            <h6 className={css.location}>{item.location}</h6>
                          </div>
                          <p className={css.content}>{item.content}</p>
                          <ReviewRating
                            reviewStarClassName={css.reviewStar}
                            className={css.reviewStars}
                            rating={item.rating}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </NamedLink>
            ))}
          </div>
        ) : null}
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCustomArticle.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionCustomArticle.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
  cardData: array,
  intl: intlShape.isRequired,
};

export default injectIntl(SectionCustomArticle);
