import React, { useEffect } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Field, { hasDataInFields } from '../../Field';
import SectionContainer from '../SectionContainer';

import css from './SectionCarouselField.module.css';
import { ReviewRating, NamedLink } from '../../../../components';

import { createSlug } from '../../../../util/urlHelpers';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';

// Section component that's able to show blocks in a carousel
// the number blocks visible is defined by "numColumns" prop.
const SectionCarouselField = props => {
  const {
    sectionId,
    className,
    rootClassName,
    title,
    description,
    appearance,
    callToAction,
    options,
    blocks,
    numColumns,
    cardsData = [],
    intl,
  } = props;
  const KEY_CODE_ARROW_LEFT = 37;
  const KEY_CODE_ARROW_RIGHT = 39;
  const sliderContainerId = `${props.sectionId}-container`;
  const sliderId = `${props.sectionId}-slider`;
  const numberOfBlocks = blocks?.length;
  const hasBlocks = numberOfBlocks > 0;
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const availabilityBadgeText = intl.formatMessage({
    id: 'ReviewsGridComponent.campingAvailability',
  });

  const gridContainerStyle = {
    backgroundImage: cardsData.backgroundImage ? `url(${backgroundImage})` : 'none',
  };

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = 1 * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = 1 * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };
    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, []);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={css.sectionDetails}>
          <Field data={title} className={css.title} options={fieldOptions} />
          <Field data={description} className={css.description} options={fieldOptions} />
          <Field data={callToAction} className={css.ctaButton} options={fieldOptions} />
        </header>
      ) : null}

      <div className={css.carouselContainer} id={sliderContainerId}>
        <div
          className={classNames(css.carouselArrows, {
            [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
          })}
        >
          <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div className={css.sectionContainer} id={sliderId}>
          {cardsData.map(item => {
            return (
              <NamedLink
                className={css.listingNamedLink}
                key={item.listingId}
                name="ListingPage"
                params={{ id: item.listingId, slug: createSlug(item.contentTitle) }}
              >
                <div className={css.sectionItem}>
                  <div className={css.itemContainer}>
                    {item.backgroundImage && (
                      <div className={css.imgContainer}>
                        <div
                          className={css.image}
                          style={{
                            ...gridContainerStyle,
                            backgroundImage: `url(${item.backgroundImage})`,
                          }}
                        ></div>
                        {item.campingAvailable && (
                          <div className={css.availabilityBadge}>{availabilityBadgeText}</div>
                        )}
                        <div className={css.contentContainer}>
                          <div className={css.titleContainer}>
                            <p className={css.contentTitle}>{item.contentTitle}</p>
                            <p className={css.location}>{item.location}</p>
                          </div>

                          {typeof item.content !== 'undefined' ? (
                            <>
                              <p className={css.content}>{item.content}</p>
                              <ReviewRating
                                reviewStarClassName={css.reviewStar}
                                className={css.reviewStars}
                                rating={item.rating}
                              />
                            </>
                          ) : (
                            <>
                              <p className={css.content}>
                                <FormattedMessage id="ReviewGrid.noReview" />
                              </p>
                              <ReviewRating
                                reviewStarClassName={css.reviewStar}
                                className={css.reviewStars}
                                rating={0}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </NamedLink>
            );
          })}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCarouselField.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionCarouselField.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
  intl: intlShape.isRequired,
};

export default injectIntl(SectionCarouselField);
